import React, { useEffect } from 'react';
import Hero from './Hero';
import JobSeeker from './JobSeeker';
import Navbar from './Navbar';
import Footer from './Footer';
import JobProvider from './JobProvider';
import Faqs from './Faqs';
import Download from './Download';
import FaqsState from './context/FaqsState';
import { useLocation, useNavigate } from 'react-router-dom';

const Landing = ({ section }) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        navigate('/');
        setTimeout(() => {
            const sectionId = section;
            const targetSection = document.getElementById(sectionId);
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1000);
    }, [section, navigate]);

    return (
        <div>
            <Navbar />
            <FaqsState>
                <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-smooth-scroll="true" data-bs-root-margin="0px 0px -10%" tabIndex="0">
                    <section id="home">
                        <Hero />
                    </section>
                    <section id="jobseeker">
                        <JobSeeker />
                    </section>
                    <section id="jobprovider">
                        <JobProvider />
                    </section>
                    <section id="faqs">
                        <Faqs />
                    </section>
                    <section id="download">
                        <Download />
                    </section>
                    <section id="contactus">
                        <Footer />
                    </section>
                </div>
            </FaqsState>
        </div>
    );
};

export default Landing;
