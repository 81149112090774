import axios from "axios"

const apiBaseUrl = `${process.env.REACT_APP_BASE_URL}/api/cmspages`

export const fetchPrivacyPolicyDetail = async () => {
    try {
        const response = await axios.get(`${apiBaseUrl}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};