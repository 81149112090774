import './App.css';

import Landing from './components/Landing';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import ScanQR from './components/installApp/ScanQR';
import IosApp from './components/installApp/IosApp';
import AndroidApp from './components/installApp/AndroidApp';
import Downloads from './components/installApp/Downloads';
import ReferCode from './components/installApp/ReferCode';
import ComingSoon from './components/ComingSoon';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsCondition from './components/TermsCondition';
import ReportSpam from './components/ReportSpam';
import ScrollButton from './components/ScrollButton';

function App() {

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<Landing />} />
          <Route exact path='/coming-soon' element={<ComingSoon />} />
          <Route exact path='/ios' element={<IosApp />} />
          <Route exact path='/android' element={<AndroidApp />} />
          <Route exact path='/downloads' element={<Downloads />} />
          <Route exact path='/refer' element={<ReferCode />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/terms-condition' element={<TermsCondition />} />
          <Route exact path='/report-condition' element={<ReportSpam />} />
          <Route exact path='/jobseeker' element={<Landing section="jobseeker" />} />
          <Route exact path='/jobprovider' element={<Landing section="jobprovider" />} />
          <Route exact path='/faqs' element={<Landing section="faqs" />} />
          <Route exact path='/download' element={<Landing section="download" />} />
          <Route exact path='/contactus' element={<Landing section="contactus" />} />
        </Routes>
      </div>
      <ScrollButton />
    </Router>
  );
}

export default App;
