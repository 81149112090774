import React, { useEffect, useState } from 'react'
import { fetchPrivacyPolicyDetail } from '../api'

const TermsCondition = () => {

    const [termsConditionDetail, setTermsConditionDetail] = useState({})

    const privacyPolicyData = async () => {
        try {
            const response = await fetchPrivacyPolicyDetail();
            setTermsConditionDetail(response);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        privacyPolicyData();
        // eslint-disable-next-line 
    }, [])

    return (
        <div className='p-7 bg-white min-h-screen'>
            <p className='text-center text-4xl font-semibold'>Terms of Service</p>
            <div className='pt-7 content' dangerouslySetInnerHTML={{ __html: termsConditionDetail.terms_condition }} ></div>
        </div>
    )
}

export default TermsCondition
