import React, { useEffect, useState } from 'react'
import { fetchPrivacyPolicyDetail } from '../api'

const ReportSpam = () => {
    const [reportSpamDetail, setReportSpamDetail] = useState({})

    const privacyPolicyData = async () => {
        try {
            const response = await fetchPrivacyPolicyDetail();
            setReportSpamDetail(response);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        privacyPolicyData();
        // eslint-disable-next-line 
    }, [])

    return (
        <div className='p-7 bg-white min-h-screen'>
            <p className='text-center text-4xl font-semibold'>Report & Spam</p>
            <div className='pt-7 content' dangerouslySetInnerHTML={{ __html: reportSpamDetail.report_spam }} ></div>
        </div>
    )
}

export default ReportSpam
